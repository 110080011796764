<template>
    <div>
        <layout-wrapper>
            <span>{{ $route.params.id ? 'Detail Email Pending' : "Daftar Email Pending" }}</span>
            <div class="clearfix"></div>
            <br>
            <div class="clearfix"></div>
            <div class="wrapp-input-contact myEmail m-t-20">
                <div v-if="$route.params.id">
                    <DetailThread :thread="thread" :routeName="'pendingemail'" @open-dialog="openDialog" />
                </div>
                <div v-else>
                    <v-row>
                        <v-col cols="6" class="">
                            <v-text-field style="font-size:14px" v-model="term" :rules="rules.required"
                                placeholder="Cari ...">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete class="label-label mb-0" v-model="selectedOperator" :items="operatorList"
                                hide-selected item-text="name" item-value="_id" label="Operator">
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="pt-0-mobile mb-4">
                            <v-btn class="mr-2 btn-primary" @click="handleSearch">
                                Cari
                            </v-btn>
                            <v-btn @click="resetFilter">
                                Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div class="clearfix"></div>
                    <v-data-table show-select :headers="headers" :items="desserts" :items-per-page="10"
                        :options.sync="options" sort-by="createdOn" :sort-desc="true"
                        :server-items-length="totalElements" :loading="isLoading" v-model="selected"
                        class="elevation-1 my-5">
                        <template v-slot:item.status="{ item }">
                            <div @click="detailThread(item.id)">
                                <v-icon v-if="!item.isReply" title="Belum Dibalas">mdi-email-outline</v-icon>
                            </div>
                        </template>
                        <template v-slot:item.from="{ item }">
                            <div class="wrapp-link-inbox" @click="detailThread(item.id)">
                                <span class="Sender" :class="{ 'thread-read': !item.isRead }">
                                    {{ item.from }}
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.snippet="{ item }">
                            <div class="emailPreview wrapp-link-inbox" @click="detailThread(item.id)">
                                <span :class="{ 'thread-read': !item.isRead }">{{ item.messages[0].title }}</span>
                            </div>

                        </template>
                        <template v-slot:item.operator="{ item }">
                            <div class="wrapp-link-inbox" @click="detailThread(item.id)">
                                <span
                                    :class="{ 'thread-read': !item.isRead }">{{ item.operator ? item.operator.name : '' }}</span>
                            </div>
                        </template>
                        <template v-slot:item.createdOn="{ item }">
                            <div class="wrapp-link-inbox" @click="detailThread(item.id)">
                                <span :class="{ 'thread-read': !item.isRead }">{{ item.time }}</span>
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <div style="min-width:155px">
                                <div class="btn-assign pull-left p-0">

                                    <b-dropdown variant="outline-secondary" size="sm" class="m-r-10" text="Rekan" right>
                                        <span style="padding: 8px 1rem; display:block">
                                            <input class="form-control" type="text" placeholder="Search"
                                                @input="filterReturn($event)" aria-label="Search" />
                                        </span>
                                        <b-dropdown-item v-for="(op) in filterOperator" :key="op.id"
                                            class="list-account">
                                            <span class="pull-left wrapp-list-account itemName">
                                                <a @click="openDialog(item, op)">
                                                    {{ op.name }}
                                                </a>
                                            </span>
                                            <span class="online-status" v-if="op.active == 1">
                                                <i style="color:green" class="mdi mdi-circle"></i>
                                            </span>
                                            <div class="clearfix"></div>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <b-button variant="outline-secondary" size="sm" @click="openDialog(item)">Saya
                                    </b-button>

                                </div>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </layout-wrapper>
        <v-dialog max-width="600" v-model="assignDialog" persistent>
            <template>
                <v-card>
                    <v-toolbar color="deep-purple accent-4" dark>
                        <span class="text-white">
                            Tangani Pesan Ini
                        </span>
                    </v-toolbar>
                    <v-card-text>
                        <div class="my-5">
                            <span v-if="!assignOperator.id">
                                Tangani email dari <strong>'{{ thread.from }}'</strong> ini sekarang? Klik tombol
                                'Sekarang' jika ingin menangani email ini sekarang, atau tombol 'Nanti' jika masih ingin
                                melihat email-email yang belum ditangani
                            </span>
                            <span v-else>
                                Berikan email dari <strong>'{{ thread.from }}'</strong> kepada
                                <strong>'{{ assignOperator.name }}'</strong> ini sekarang? Klik tombol 'Sekarang' jika
                                ingin email ini ditangani oleh <strong>'{{ assignOperator.name }}'</strong> sekarang,
                                atau tombol 'Nanti' jika masih ingin melihat email-email yang belum ditangani
                            </span>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="assignDialog = false">Nanti</v-btn>
                        <v-btn color="deep-purple accent-4" dark @click="assignEmail" :loading="isLoadingAssign">
                            <span class="text-white">
                                Sekarang
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState,
        mapActions
    } from 'vuex'

    const $ = require("jquery")
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');

    const {
        toFriendlyDateTime
    } = require('../utils/date-utils');

    import DetailThread from '../components/DetailThread.vue'
import LayoutWrapper from '../layouts/components/LayoutWrapper.vue'

    var messageScrollBar = null;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                operatorList: state => state.operator.operatorList,
                threadCallback: state => state.socket.emailThreadCallback,
                threadUpdateCallback: state => state.socket.emailThreadUpdateCallback,
                messageCallback: state => state.socket.emailMessageCallback
            })
        },
        data: function () {
            return {
                totalElements: 0,

                term: String(),
                selectedOperator: String(),
                
                desserts: Array(),
                selected: Array(),
                filterOperator: Array(),

                options: Object(),
                thread: Object(),
                assignOperator: Object(),

                isLoading: false,
                formValid: false,
                assignDialog: false,
                isLoadingAssign: false,

                formDialog: {
                    to: String(),
                    subject: String(),
                    text: String()
                },

                headers: [{
                        text: 'Status',
                        value: 'status'
                    },{
                        text: 'Pengirim',
                        value: 'from'
                    },
                    {
                        text: 'Subjek',
                        value: 'snippet',
                        sortable: false
                    },
                    {
                        text: 'Operator',
                        value: 'operator',
                        sortable: false
                    },
                    {
                        text: 'Tanggal',
                        value: 'createdOn'
                    },
                    {
                        text: 'Alihkan Penanganan',
                        value: 'action',
                        sortable: false
                    },
                ],

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            options: {
                async handler() {
                    await this.loadEmailList();
                },
                deep: true,
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            $route: async function(val) {
                const params = val.params

                this.thread = Object()
                if(params.id) {
                    await this.loadEmail(params.id)
                    messageScrollBar.update();
                    messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                }
            },
            threadCallback: function(val) {
                if(val) {
                    if(val.status != 'pending') return
                    if(this.selectedOperator && !val.operator) return
                    if(this.selectedOperator && this.selectedOperator != val.operator.id) return

                    let desserts = [...this.desserts]
                    const index = desserts.findIndex(e => e._id == val._id)

                    val.id = val._id
                    val.createdOn = parseInt(val.createdOn)
                    val.time = toFriendlyDateTime(val.createdOn)

                    if(index >= 0) desserts.splice(index, 1)
    
                    desserts.unshift(val)
                    
                    this.desserts = desserts.slice(0, 10)
                }
            },
            threadUpdateCallback: function(val) {
                if(val) {
                    let desserts = [...this.desserts]
                    const index = desserts.findIndex(e => e._id == val._id)

                    val.id = val._id
                    val.time = toFriendlyDateTime(val.createdOn)

                    let deletedIndex = null
                    
                    if(index >= 0) {
                        desserts.splice(index, 1, val)

                        if(val.status != 'pending') deletedIndex = index
                        if(this.selectedOperator && !val.operator) deletedIndex = index
                        if(this.selectedOperator && this.selectedOperator != val.operator.id) deletedIndex = index
                    } 

                    if(deletedIndex != null) desserts.splice(deletedIndex, 1)

                    this.desserts = desserts
                }
            },
            messageCallback: function(val) {
                if(val && this.thread && val.threadId == this.thread._id) {
                    let data = { ...this.thread }
                    const index = data.messages.findIndex(e => e._id == val._id)

                    if(index < 0) {
                        val.time = toFriendlyDateTime(val.createdOn)
    
                        if(val.attachments && val.attachments.length > 0) {
                            val.attachments = val.attachments.map(el => {
                                const splitted = el.mimeType.split("/")
                                el.contentType = splitted[0]
    
                                return el
                            })
                        }
    
                        if(val.direction == 'IN' && val.from.includes("<")) {
                            const splitted = val.from.split('<')
                            val.fromName = splitted[0].substring(0, splitted[0].length - 1); 
                            val.fromEmail = splitted[1].substring(0, splitted[1].length - 1); 
                        }
    
                        data.messages.push(val)
    
                        this.thread = data
                    }

                    messageScrollBar.update();

                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                    })
                }
            }
        },
        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList',
                actGetThreadCount: 'getThreadCount'
            }),
            openDialog(item, operator) {
                this.thread = item

                if(operator) {
                    this.assignOperator = {
                        id: operator._id,
                        name: operator.name
                    }
                }

                this.assignDialog = true
            },
            filterReturn: function (event) {
                this.filterOperator = this.operatorList.filter(e => e.name.toLowerCase().includes(event.target.value.toLowerCase()))
            },
            handleSearch: async function() {
                this.options.page = 1
                await this.loadEmailList()
            },
            resetFilter: async function() {
                this.term = String()
                this.selectedOperator = String()
                this.options.page = 1

                await this.loadEmailList()
            },
            loadInitialData: async function () {
                await this.initOperatorList()

                this.filterOperator = this.operatorList
            },
            detailThread: async function(id) {
                this.$router.push({
                    name: 'pendingemail',
                    params: { id: encodeURIComponent(id) }
                });
            },
            assignEmail: async function() {
                try {
                    this.isLoadingAssign = true

                    let body = Object()

                    if(this.assignOperator.id) {
                        body.operatorId = this.assignOperator.id
                        body.operatorName = this.assignOperator.name
                        await axios.put(process.env.VUE_APP_API_URL + `/email/threads-sign-to-other/${this.thread._id}`, body)
                    } else {
                        await axios.put(process.env.VUE_APP_API_URL + `/email/threads-sign-to-me/${this.thread._id}`, body)
                    }

                    this.isLoadingAssign = false
                    this.assignDialog = false

                    if(!this.assignOperator.id) {
                        this.$router.push({
                            name: 'myemail',
                            params: { id: encodeURIComponent(this.thread._id) }
                        });
                    } else {
                        if(this.$route.params.id) {
                            this.$router.push({ name: 'pendingemail' });
                        } else {
                            await this.loadEmailList()
                        }
                    }

                    this.assignOperator = Object()

                } catch (error) {
                    this.isLoadingAssign = false
                    console.log("assign-email", error);      
                }
            },
            loadEmail: async function(id) {
                this.isLoading = true
                
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + `/email/threads/${id}`)

                    data.messages = data.messages.map(e => {
                        e.time = toFriendlyDateTime(e.createdOn)

                        if(e.attachments && e.attachments.length > 0) {
                            e.attachments = e.attachments.map(el => {
                                const splitted = el.mimeType.split("/")
                                el.contentType = splitted[0]

                                return el
                            })
                        }

                        if(e.direction == 'IN' && e.from.includes("<")) {
                            const splitted = e.from.split('<')
                            e.fromName = splitted[0].substring(0, splitted[0].length - 1); 
                            e.fromEmail = splitted[1].substring(0, splitted[1].length - 1); 
                        }

                        return e
                    })

                    this.thread = data
                } catch (error) {
                    console.log("load-email-list", error);
                }

                this.isLoading = false
            },
            loadEmailList: async function() {
                this.isLoading = true
                let params = Object();

                params.term = this.term
                params.sort = 'createdOn,desc'
                params.operatorId = this.selectedOperator

                if (this.options && this.options.page) params.page = this.options.page;
                if (this.options && this.options.itemsPerPage) params.size = this.options.itemsPerPage;
                if (this.options && this.options.sortBy && this.options.sortBy.length > 0) {
                    const sortBy = this.options.sortBy[0];
                    const sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc';
                    params.sort = sortBy + ',' + sortOrder
                }
                
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/email/threads-pending', {
                        params
                    })

                    this.desserts = data.content.map(e => {
                        e.id = e._id
                        e.time = toFriendlyDateTime(e.createdOn)
                        
                        return e
                    })

                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log("load-email-list", error);
                }
                this.isLoading = false
            },
            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            Scrollbar.initAll();
            messageScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);

            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
                this.actGetThreadCount();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            if(this.$route.params && this.$route.params.id) {
                await this.loadEmail(this.$route.params.id)

                messageScrollBar.update();
                messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
            }
        },
        components: {
            'layout-wrapper': LayoutWrapper,
            // ErrorMessage,
            SuccessNotif,
            DetailThread,
        }
    }
</script>